import gql from 'graphql-tag';

const EVENT_GRID_EVENT_PROPERTY_MAP = gql`
  fragment EventGridEventPropertyMap on Event {
    propertyMap {
      accessible {
        id
        key
        name
        __typename
      }
      alcoholForSale {
        id
        key
        name
        __typename
      }
      alcoholNotPermittedOrSold {
        id
        key
        name
        __typename
      }
      byob {
        id
        key
        name
        __typename
      }
      faceMaskRequired {
        id
        key
        name
        __typename
      }
      faceMaskNotRequired {
        id
        key
        name
        __typename
      }
      foodAvailableForSale {
        id
        key
        name
        __typename
      }
      noOutsideFood {
        id
        key
        name
        __typename
      }
      outdoors {
        id
        key
        name
        __typename
      }
      over18Only {
        id
        key
        name
        __typename
      }
      over21Only {
        id
        key
        name
        __typename
      }
      over23Only {
        id
        key
        name
        __typename
      }
      over25Only {
        id
        key
        name
        __typename
      }
      petPresent {
        id
        key
        name
        __typename
      }
      __typename
    }
  }
`;

const EVENT_PROPERTY_MAP = gql`
  fragment EventPropertyMapForFan on Event {
    propertyMap {
      accessible {
        id
        key
        name
        __typename
      }
      alcoholForSale {
        id
        key
        name
        __typename
      }
      alcoholNotPermittedOrSold {
        id
        key
        name
        __typename
      }
      byob {
        id
        key
        name
        __typename
      }
      cat {
        id
        key
        name
        __typename
      }
      dog {
        id
        key
        name
        __typename
      }
      faceMaskRequired {
        id
        key
        name
        __typename
      }
      faceMaskNotRequired {
        id
        key
        name
        __typename
      }
      foodAvailableForSale {
        id
        key
        name
        __typename
      }
      fourTwentyFriendly {
        id
        key
        name
        __typename
      }
      freePublicParkingNearby {
        id
        key
        name
        __typename
      }
      noOutsideFood {
        id
        key
        name
        __typename
      }
      noParking {
        id
        key
        name
        __typename
      }
      noRedWine {
        id
        key
        name
        __typename
      }
      notAccessible {
        id
        key
        name
        __typename
      }
      outdoors {
        id
        key
        name
        __typename
      }
      over18Only {
        id
        key
        name
        __typename
      }
      over21Only {
        id
        key
        name
        __typename
      }
      over23Only {
        id
        key
        name
        __typename
      }
      over25Only {
        id
        key
        name
        __typename
      }
      paidParking {
        id
        key
        name
        __typename
      }
      smokingNotPermitted {
        id
        key
        name
        __typename
      }
      smokingPermitted {
        id
        key
        name
        __typename
      }
      stairs {
        id
        key
        name
        __typename
      }
      __typename
    }
    __typename
  }
`;

// TODO: Optimize this query of things we dont need for the Checkout Event
export const GET_CHECKOUT_EVENT = gql`
  query GetCheckoutEvent($id: ID) {
    event(id: $id) {
      artists {
        id
        cachedSlug
        title
        description
        imageThumb
        imageUrl
      }
      id
      isPublished
      isGloballyPromoted
      cancelled
      isSpecialEvent
      localStartsAt
      startsAt
      guestsArriveAt
      artistLoadInAt
      onSaleAt
      price
      remainingSpaces
      maxTicketsPerAttendee
      billingRegion
      billingPublishableKey
      isPurchasable
      isAvailable
      imageUrl
      landscapeImageUrl
      theme {
        id
        title
      }
      byob
      alcoholForSale
      alcoholNotPermittedOrSold
      foodAvailableForSale
      noOutsideFood
      isOver21
      isOver18
      attendeeFlow
      isOutdoor
      showPet
      accessible
      showExternalEventNotes
      externalEventNotes
      isVenueConfirmed
      internalVenueNotes
      numTicketsAvailableForSale
      type
      cachedSlug
      serviceFee
      bookingFee
      onSaleEnded
      inOnSaleWindow
      eventAttendeeGuestsConfirmedCount
      onPresale
      sofarOperatedMarketType
      venue {
        venueName
        showByob
        alcoholForSale
        alcoholNotPermittedOrSold
        foodAvailableForSale
        noOutsideFood
        isOver21
        isOver18
        showPet
        accessible
        isOutdoor
        capacity
        venueType
        closestStation
        address
        id
        latitude
        longitude
        neighborhood {
          id
          title
        }
      }
      city {
        id
        title
        cachedSlug
        timezone
        latitude
        longitude
        country {
          id
          title
          currency
          countryCode
          currencySymbol
          cachedSlug
        }
      }
      competition {
        id
        title
      }
      campaign {
        id
        title
        partner {
          id
          title
          description
        }
      }
      eventOrganizedAs
    }
  }
`;

export const GET_EVENTS_AVAILABILITY_FOR_EVENT_GRID = gql`
  query GetEventsAvailabilityForEventGrid(
    $eventSearch: String
    $city: String
    $includeNearbySecondaryCities: Boolean
    $businessOwner: String
    $neighborhood: String
    $date: String
    $numTicketsAvailableForSale: String
    $theme: String
    $tag: String
    $offerId: Int
    $openPositions: String
    $artistIds: String
    $curatorGroup: String
    $roleAvailable: String
    $isStaffedByCurrentUser: Boolean
    $eventIds: [String]
    $excludeSoldOut: Boolean
    $excludePresale: Boolean
    $excludeNonPresale: Boolean
    $indoorOutdoor: String
    $isByob: Boolean
    $maxSlotsTaken: Int
    $upcoming: Boolean
    $upcomingOrCurrent: Boolean
    $published: Boolean
    $globallyPromoted: Boolean
    $type: String
    $eventOrganizedAs: String
    $attendeeTicketingFlow: String
    $status: String
    $minRemainingSpaces: Int
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    events(
      eventSearch: $eventSearch
      city: $city
      includeNearbySecondaryCities: $includeNearbySecondaryCities
      businessOwner: $businessOwner
      neighborhood: $neighborhood
      date: $date
      numTicketsAvailableForSale: $numTicketsAvailableForSale
      theme: $theme
      tag: $tag
      offerId: $offerId
      openPositions: $openPositions
      artistIds: $artistIds
      curatorGroup: $curatorGroup
      roleAvailable: $roleAvailable
      isStaffedByCurrentUser: $isStaffedByCurrentUser
      eventIds: $eventIds
      excludeSoldOut: $excludeSoldOut
      excludePresale: $excludePresale
      excludeNonPresale: $excludeNonPresale
      indoorOutdoor: $indoorOutdoor
      isByob: $isByob
      maxSlotsTaken: $maxSlotsTaken
      globallyPromoted: $globallyPromoted
      upcoming: $upcoming
      upcomingOrCurrent: $upcomingOrCurrent
      published: $published
      type: $type
      eventOrganizedAs: $eventOrganizedAs
      attendeeTicketingFlow: $attendeeTicketingFlow
      status: $status
      minRemainingSpaces: $minRemainingSpaces
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      events {
        id
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_EVENTS_FOR_EVENT_GRID = gql`
  ${EVENT_GRID_EVENT_PROPERTY_MAP}
  query GetEventsForEventGrid(
    $eventSearch: String
    $city: String
    $includeNearbySecondaryCities: Boolean
    $businessOwner: String
    $neighborhood: String
    $date: String
    $numTicketsAvailableForSale: String
    $theme: String
    $tag: String
    $offerId: Int
    $openPositions: String
    $artistIds: String
    $curatorGroup: String
    $roleAvailable: String
    $isStaffedByCurrentUser: Boolean
    $eventIds: [String]
    $excludeSoldOut: Boolean
    $excludePresale: Boolean
    $excludeNonPresale: Boolean
    $indoorOutdoor: String
    $isByob: Boolean
    $maxSlotsTaken: Int
    $upcoming: Boolean
    $upcomingOrCurrent: Boolean
    $published: Boolean
    $globallyPromoted: Boolean
    $type: String
    $eventOrganizedAs: String
    $attendeeTicketingFlow: String
    $status: String
    $minRemainingSpaces: Int
    $loadDynamicHeaderImages: Boolean
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    events(
      eventSearch: $eventSearch
      city: $city
      includeNearbySecondaryCities: $includeNearbySecondaryCities
      businessOwner: $businessOwner
      neighborhood: $neighborhood
      date: $date
      numTicketsAvailableForSale: $numTicketsAvailableForSale
      theme: $theme
      tag: $tag
      offerId: $offerId
      openPositions: $openPositions
      artistIds: $artistIds
      curatorGroup: $curatorGroup
      roleAvailable: $roleAvailable
      isStaffedByCurrentUser: $isStaffedByCurrentUser
      eventIds: $eventIds
      excludeSoldOut: $excludeSoldOut
      excludePresale: $excludePresale
      excludeNonPresale: $excludeNonPresale
      indoorOutdoor: $indoorOutdoor
      isByob: $isByob
      maxSlotsTaken: $maxSlotsTaken
      globallyPromoted: $globallyPromoted
      upcoming: $upcoming
      upcomingOrCurrent: $upcomingOrCurrent
      published: $published
      type: $type
      eventOrganizedAs: $eventOrganizedAs
      attendeeTicketingFlow: $attendeeTicketingFlow
      status: $status
      minRemainingSpaces: $minRemainingSpaces
      loadDynamicHeaderImages: $loadDynamicHeaderImages
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      events {
        id
        guestsArriveAt
        localStartsAt
        startsAt
        price
        isPurchasable
        isAvailable
        isSoldOut
        cancelled
        city {
          id
          title
          timezone
          country {
            id
            countryCode
            currency
            currencySymbol
          }
        }
        isVenueConfirmed
        neighborhood {
          id
          title
        }
        venue {
          id
          neighborhood {
            id
            title
          }
          venueType
        }
        theme {
          id
          title
        }
        attendeeFlow
        ...EventGridEventPropertyMap
        attendee {
          id
          tickets {
            id
          }
        }
        imageUrl
        isVenueConfirmed
        onPresale
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_EVENTS_FOR_FILMSTRIP_SECTION = gql`
  query GetEventsForFilmstripSection(
    $city: String
    $excludePresale: Boolean
    $excludeSoldOut: Boolean
    $globallyPromoted: Boolean
    $includeNearbySecondaryCities: Boolean
    $page: Int
    $perPage: Int
    $published: Boolean
    $type: String
    $upcoming: Boolean
  ) {
    events(
      city: $city
      excludePresale: $excludePresale
      excludeSoldOut: $excludeSoldOut
      globallyPromoted: $globallyPromoted
      includeNearbySecondaryCities: $includeNearbySecondaryCities
      page: $page
      perPage: $perPage
      published: $published
      type: $type
      upcoming: $upcoming
    ) {
      events {
        id
        guestsArriveAt
        imageUrl
        isVenueConfirmed
        venue {
          id
          neighborhood {
            id
            title
          }
          venueType
          venueCategories {
            id
            name
            isVisibleToFans
          }
        }
        neighborhood {
          id
          title
        }
        city {
          id
          title
          timezone
          cachedSlug
          country {
            id
            currencySymbol
            countryCode
          }
        }
        isPurchasable
        cancelled
        onPresale
        theme {
          id
          title
        }
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_EVENT_FOR_FAN = gql`
  ${EVENT_PROPERTY_MAP}
  query GetEventForFan(
    $id: ID
    $cachedSlug: String
    $includeTodaysArtists: Boolean
  ) {
    event(
      id: $id
      cachedSlug: $cachedSlug
      includeTodaysArtists: $includeTodaysArtists
    ) {
      id
      isPublished
      isFilmed
      isGloballyPromoted
      cancelled
      isSpecialEvent
      specialEventUrl
      localStartsAt
      startsAt
      guestsArriveAt
      artistLoadInAt
      onSaleAt
      price
      remainingSpaces
      maxTicketsPerAttendee
      billingRegion
      billingPublishableKey
      isPurchasable
      isAvailable
      isAppliable
      isSoldOut
      landscapeImageUrl
      landscapeNarrowImageUrl
      landscapeVeryNarrowImageUrl
      theme {
        id
        title
      }
      attendeeFlow
      faceMaskRequired
      faceMaskNotRequired
      showExternalEventNotes
      externalEventNotes
      type
      cachedSlug
      onSaleEnded
      inOnSaleWindow
      eventAttendeeGuestsConfirmedCount
      onPresale
      sofarOperatedMarketType
      bookingFee
      externalVenueNotes
      endsAt
      isVenueConfirmed
      genres
      artists {
        id
        cachedSlug
        title
        description
        imageThumb
        socialUrls {
          urlWebsite
          urlFacebook
          urlTwitter
          urlYoutube
          urlBandcamp
          urlSoundcloud
          urlInstagram
          urlSpotify
          urlSupport
        }
      }
      neighborhood {
        id
        title
      }
      venue {
        venueName
        capacity
        closestStation
        address
        id
        latitude
        longitude
        venueType
        venueCategories {
          id
          name
          isVisibleToFans
        }
        neighborhood {
          id
          title
          cachedSlug
        }
        socialUrls {
          urlWebsite
        }
      }
      city {
        id
        title
        cachedSlug
        timezone
        bookingFee
        displayBookingFee
        country {
          id
          title
          currency
          currencySymbol
          cachedSlug
          countryCode
          bookingFee
          displayBookingFee
        }
      }
      competition {
        id
        title
        uid
      }
      eventOrganizedAs
      seating {
        key
        name
      }
      images {
        header {
          ratio1x1 {
            id
            ratio
            filename
            fileLocation
          }
          ratio4x3 {
            id
            ratio
            filename
            fileLocation
          }
          ratio16x9 {
            id
            ratio
            filename
            fileLocation
          }
        }
      }
      eventCollections {
        id
        title
        status
        description
        eventDescription
        eventHeadline
        createdAt
        images {
          header {
            ratio16x9 {
              id
              ratio
              filename
              fileLocation
            }
          }
        }
        createdAt
        merchandisingProperties {
          id
          propertyGroupId
          propertyGroupName
          key
          name
          iconName
          position
        }
      }
      headline
      merchandisingProperties {
        id
        propertyGroupId
        propertyGroupName
        key
        name
        iconName
        position
      }
      ...EventPropertyMapForFan
    }
  }
`;

export const GET_EVENTS_FOR_CITY_PAGE = gql`
  query GetEventsForCityPage(
    $city: String
    $date: String
    $excludeCancelled: Boolean
    $excludeNonPresale: Boolean
    $excludePresale: Boolean
    $excludeSoldOut: Boolean
    $globallyPromoted: Boolean
    $includeNearbySecondaryCities: Boolean
    $indoorOutdoor: String
    $isByob: Boolean
    $loadDynamicHeaderImages: Boolean
    $page: Int
    $perPage: Int
    $published: Boolean
    $upcoming: Boolean
  ) {
    events(
      city: $city
      date: $date
      excludeCancelled: $excludeCancelled
      excludeNonPresale: $excludeNonPresale
      excludePresale: $excludePresale
      excludeSoldOut: $excludeSoldOut
      globallyPromoted: $globallyPromoted
      includeNearbySecondaryCities: $includeNearbySecondaryCities
      indoorOutdoor: $indoorOutdoor
      isByob: $isByob
      loadDynamicHeaderImages: $loadDynamicHeaderImages
      page: $page
      perPage: $perPage
      published: $published
      upcoming: $upcoming
    ) {
      events {
        id
        guestsArriveAt
        localStartsAt
        startsAt
        onPresale
        isAppliable
        isPublished
        isPurchasable
        cancelled
        isSoldOut
        attendeeFlow
        city {
          id
          title
          timezone
          cachedSlug
          country {
            id
            countryCode
          }
        }
        isVenueConfirmed
        genres
        neighborhood {
          id
          title
        }
        venue {
          id
          neighborhood {
            id
            title
          }
          venueType
          venueCategories {
            id
            name
            isVisibleToFans
          }
        }
        theme {
          id
          title
        }
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_EVENTS_FOR_CITY_PAGE_VISA_COLLECTION = gql`
  query GetEventsForCityPageVisaCollection(
    $city: String
    $excludeCancelled: Boolean
    $excludeNonPresale: Boolean
    $excludePresale: Boolean
    $excludeSoldOut: Boolean
    $globallyPromoted: Boolean
    $includeNearbySecondaryCities: Boolean
    $loadDynamicHeaderImages: Boolean
    $published: Boolean
    $skipPagination: Boolean
    $upcoming: Boolean
  ) {
    events(
      city: $city
      excludeCancelled: $excludeCancelled
      excludeNonPresale: $excludeNonPresale
      excludePresale: $excludePresale
      excludeSoldOut: $excludeSoldOut
      globallyPromoted: $globallyPromoted
      includeNearbySecondaryCities: $includeNearbySecondaryCities
      loadDynamicHeaderImages: $loadDynamicHeaderImages
      published: $published
      skipPagination: $skipPagination
      upcoming: $upcoming
    ) {
      events {
        id
        guestsArriveAt
        localStartsAt
        startsAt
        onPresale
        isAppliable
        isPublished
        isPurchasable
        cancelled
        isSoldOut
        attendeeFlow
        city {
          id
          title
          timezone
          cachedSlug
          country {
            id
            countryCode
          }
        }
        isVenueConfirmed
        genres
        neighborhood {
          id
          title
        }
        venue {
          id
          neighborhood {
            id
            title
          }
          venueType
          venueCategories {
            id
            name
            isVisibleToFans
          }
        }
        theme {
          id
          title
        }
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_EVENTS_FOR_EVENT_PAGE = gql`
  query GetEventsForEventPage(
    $eventSearch: String
    $city: String
    $includeNearbySecondaryCities: Boolean
    $businessOwner: String
    $neighborhood: String
    $date: String
    $numTicketsAvailableForSale: String
    $theme: String
    $tag: String
    $offerId: Int
    $openPositions: String
    $artistIds: String
    $curatorGroup: String
    $roleAvailable: String
    $isStaffedByCurrentUser: Boolean
    $eventIds: [String]
    $excludeSoldOut: Boolean
    $excludePresale: Boolean
    $excludeNonPresale: Boolean
    $indoorOutdoor: String
    $isByob: Boolean
    $maxSlotsTaken: Int
    $upcoming: Boolean
    $upcomingOrCurrent: Boolean
    $published: Boolean
    $globallyPromoted: Boolean
    $type: String
    $eventOrganizedAs: String
    $attendeeTicketingFlow: String
    $status: String
    $minRemainingSpaces: Int
    $loadDynamicHeaderImages: Boolean
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    events(
      eventSearch: $eventSearch
      city: $city
      includeNearbySecondaryCities: $includeNearbySecondaryCities
      businessOwner: $businessOwner
      neighborhood: $neighborhood
      date: $date
      numTicketsAvailableForSale: $numTicketsAvailableForSale
      theme: $theme
      tag: $tag
      offerId: $offerId
      openPositions: $openPositions
      artistIds: $artistIds
      curatorGroup: $curatorGroup
      roleAvailable: $roleAvailable
      isStaffedByCurrentUser: $isStaffedByCurrentUser
      eventIds: $eventIds
      excludeSoldOut: $excludeSoldOut
      excludePresale: $excludePresale
      excludeNonPresale: $excludeNonPresale
      indoorOutdoor: $indoorOutdoor
      isByob: $isByob
      maxSlotsTaken: $maxSlotsTaken
      globallyPromoted: $globallyPromoted
      upcoming: $upcoming
      upcomingOrCurrent: $upcomingOrCurrent
      published: $published
      type: $type
      eventOrganizedAs: $eventOrganizedAs
      attendeeTicketingFlow: $attendeeTicketingFlow
      status: $status
      minRemainingSpaces: $minRemainingSpaces
      loadDynamicHeaderImages: $loadDynamicHeaderImages
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      events {
        id
        guestsArriveAt
        localStartsAt
        startsAt
        price
        isAppliable
        isPublished
        isPurchasable
        isSoldOut
        city {
          id
          title
          cachedSlug
          timezone
          country {
            id
            currencySymbol
            countryCode
          }
        }
        venue {
          id
          venueType
          neighborhood {
            title
            id
          }
          venueCategories {
            id
            name
            isVisibleToFans
          }
        }
        theme {
          id
          title
        }
        byob
        attendeeFlow
        isOutdoor
        accessible
        attendee {
          id
          currentState
          tickets {
            id
          }
        }
        images {
          header {
            ratio4x3 {
              id
              ratio
              filename
              fileLocation
            }
            ratio16x9 {
              id
              ratio
              filename
              fileLocation
            }
          }
        }
        imageUrl
        isVenueConfirmed
        genres
        onPresale
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_EVENTS_FOR_TODAYS_EVENTS_PAGE = gql`
  query GetEventsForTodaysEventPage(
    $eventSearch: String
    $city: String
    $includeNearbySecondaryCities: Boolean
    $businessOwner: String
    $neighborhood: String
    $date: String
    $numTicketsAvailableForSale: String
    $theme: String
    $tag: String
    $offerId: Int
    $openPositions: String
    $artistIds: String
    $curatorGroup: String
    $roleAvailable: String
    $isStaffedByCurrentUser: Boolean
    $eventIds: [String]
    $excludeSoldOut: Boolean
    $excludePresale: Boolean
    $excludeNonPresale: Boolean
    $indoorOutdoor: String
    $isByob: Boolean
    $maxSlotsTaken: Int
    $upcoming: Boolean
    $upcomingOrCurrent: Boolean
    $published: Boolean
    $globallyPromoted: Boolean
    $type: String
    $eventOrganizedAs: String
    $attendeeTicketingFlow: String
    $status: String
    $minRemainingSpaces: Int
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    events(
      eventSearch: $eventSearch
      city: $city
      includeNearbySecondaryCities: $includeNearbySecondaryCities
      businessOwner: $businessOwner
      neighborhood: $neighborhood
      date: $date
      numTicketsAvailableForSale: $numTicketsAvailableForSale
      theme: $theme
      tag: $tag
      offerId: $offerId
      openPositions: $openPositions
      artistIds: $artistIds
      curatorGroup: $curatorGroup
      roleAvailable: $roleAvailable
      isStaffedByCurrentUser: $isStaffedByCurrentUser
      eventIds: $eventIds
      excludeSoldOut: $excludeSoldOut
      excludePresale: $excludePresale
      excludeNonPresale: $excludeNonPresale
      indoorOutdoor: $indoorOutdoor
      isByob: $isByob
      maxSlotsTaken: $maxSlotsTaken
      globallyPromoted: $globallyPromoted
      upcoming: $upcoming
      upcomingOrCurrent: $upcomingOrCurrent
      published: $published
      type: $type
      eventOrganizedAs: $eventOrganizedAs
      attendeeTicketingFlow: $attendeeTicketingFlow
      status: $status
      minRemainingSpaces: $minRemainingSpaces
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      events {
        id
        isVenueConfirmed
        venue {
          id
          neighborhood {
            id
            title
          }
          venueType
          venueCategories {
            id
            name
            isVisibleToFans
          }
        }
        neighborhood {
          id
          title
        }
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;
