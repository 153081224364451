import { useLazyQuery } from 'react-apollo';

import {
  GetCardRedemptionsStatusData,
  GetCardRedemptionsStatusVariables,
} from 'app/typings/redemptions';
import { queryParams } from 'app/shared/utils/queries';
import { GET_CARD_REDEMPTIONS_STATUS } from 'app/shared/graphql/redemptions/queries';

export const GetCardRedemptionsStatusLazy = (
  params: GetCardRedemptionsStatusVariables = {}
) => {
  return useLazyQuery<GetCardRedemptionsStatusData>(
    GET_CARD_REDEMPTIONS_STATUS,
    queryParams(params)
  );
};
