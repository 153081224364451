import { QueryHookOptions, useLazyQuery, useQuery } from 'react-apollo';

import {
  EventForCityPage,
  EventForEventGrid,
  EventForEventPage,
  EventForFilmstripSection,
  GetEventForFanData,
  GetEventForFanVariables,
  Neighborhood,
  SharedGetDataParameters,
  VenueForFanFacingEvent,
} from 'app/typings';
import { queryParams } from 'app/shared/utils/queries';
import { CheckoutEvent } from 'app/fan/utils/checkout';

import {
  GET_CHECKOUT_EVENT,
  GET_EVENT_FOR_FAN,
  GET_EVENTS_AVAILABILITY_FOR_EVENT_GRID,
  GET_EVENTS_FOR_CITY_PAGE,
  GET_EVENTS_FOR_CITY_PAGE_VISA_COLLECTION,
  GET_EVENTS_FOR_EVENT_GRID,
  GET_EVENTS_FOR_EVENT_PAGE,
  GET_EVENTS_FOR_FILMSTRIP_SECTION,
  GET_EVENTS_FOR_TODAYS_EVENTS_PAGE,
} from './queries';

interface GetCheckoutEventVariables extends SharedGetDataParameters {
  id?: string | number;
}

interface GetCheckoutEventData {
  event: CheckoutEvent;
}

export interface GetEventsVariables extends SharedGetDataParameters {
  eventSearch?: string | null;
  city?: string;
  cityIds?: string;
  includeNearbySecondaryCities?: boolean;
  neighborhood?: string;
  date?: string;
  type?: string;
  eventOrganizedAs?: string;
  status?: string;
  attendeeTicketingFlow?: string;
  minRemainingSpaces?: number;
  numTicketsAvailableForSale?: string;
  theme?: string;
  tag?: string;
  offerId?: number;
  venueIds?: string;
  isStaffedByCurrentUser?: boolean;
  eventIds?: string[];
  excludeArtistIds?: string;
  excludeSoldOut?: boolean;
  excludePresale?: boolean;
  excludeNonPresale?: boolean;
  excludeCancelled?: boolean;
  indoorOutdoor?: string;
  upcoming?: boolean;
  upcomingOrCurrent?: boolean;
  published?: boolean;
  globallyPromoted?: boolean;
  isByob?: boolean;
  notYetBooked?: String;
  bookings?: String;
  maxSlotsTaken?: number;
  loadDynamicHeaderImages?: boolean;
  orderBy?: string;
  orderDirection?: string;
  page?: number;
  perPage?: number;
  skipPagination?: boolean;
  skip?: boolean;
}

export interface GetEventsForCityPageData {
  events: {
    __typename?: string;
    events: EventForCityPage[];
    metadata: {
      totalRecords: number;
      currentPage: number;
      __typename?: string;
    };
  };
}

export const GetCheckoutEventLazy = (params: GetCheckoutEventVariables) => {
  return useLazyQuery<GetCheckoutEventData, GetCheckoutEventVariables>(
    GET_CHECKOUT_EVENT,
    queryParams(params)
  );
};

export const GetEventForFan = (params: GetEventForFanVariables = {}) => {
  return useQuery<GetEventForFanData>(GET_EVENT_FOR_FAN, queryParams(params));
};

export const GetEventsForCityPageLazy = (params: GetEventsVariables) => {
  return useLazyQuery<GetEventsForCityPageData, GetEventsVariables>(
    GET_EVENTS_FOR_CITY_PAGE,
    queryParams(params)
  );
};

export const GetEventsForCityPageVisaCollectionLazy = (
  params: GetEventsVariables
) => {
  return useLazyQuery<GetEventsForCityPageData, GetEventsVariables>(
    GET_EVENTS_FOR_CITY_PAGE_VISA_COLLECTION,
    queryParams(params)
  );
};

export interface GetEventsForEventPageData {
  events: {
    __typename?: string;
    events: EventForEventPage[];
    metadata: {
      totalRecords: number;
      currentPage: number;
      __typename?: string;
    };
  };
}

export const GetEventsForEventPage = (
  params: QueryHookOptions<GetEventsForEventPageData, GetEventsVariables>
) => {
  return useQuery<GetEventsForEventPageData, GetEventsVariables>(
    GET_EVENTS_FOR_EVENT_PAGE,
    params
  );
};

export interface EventForTodaysEventsPage {
  id: string;
  isVenueConfirmed: boolean;
  venue: VenueForFanFacingEvent;
  neighborhood?: Neighborhood;
}

interface GetEventsForTodaysEventsPageData {
  events: {
    __typename?: string;
    events: EventForTodaysEventsPage[];
    metadata: {
      totalRecords: number;
      currentPage: number;
      __typename?: string;
    };
  };
}

export const GetEventsForTodaysEventsPage = (params: GetEventsVariables) => {
  return useQuery<GetEventsForTodaysEventsPageData>(
    GET_EVENTS_FOR_TODAYS_EVENTS_PAGE,
    queryParams(params)
  );
};

export interface GetEventsAvailabilityForEventsGridData {
  events: {
    __typename?: string;
    events: {
      id: string;
    }[];
    metadata: {
      totalRecords: number;
      currentPage: number;
      __typename?: string;
    };
  };
}

export const GetEventsAvailabilityForEventsGrid = (
  params: QueryHookOptions<
    GetEventsAvailabilityForEventsGridData,
    GetEventsVariables
  >
) => {
  return useQuery<GetEventsAvailabilityForEventsGridData, GetEventsVariables>(
    GET_EVENTS_AVAILABILITY_FOR_EVENT_GRID,
    params
  );
};

export interface GetEventsForEventGridData {
  events: {
    __typename?: string;
    events: EventForEventGrid[];
    metadata: {
      totalRecords: number;
      currentPage: number;
      __typename?: string;
    };
  };
}

export const GetEventsForEventGrid = (
  params: QueryHookOptions<GetEventsForEventGridData, GetEventsVariables>
) => {
  return useQuery<GetEventsForEventGridData, GetEventsVariables>(
    GET_EVENTS_FOR_EVENT_GRID,
    params
  );
};

export interface GetEventsForFilmstripSectionData {
  events: {
    __typename?: string;
    events: EventForFilmstripSection[];
    metadata: {
      totalRecords: number;
      currentPage: number;
      __typename?: string;
    };
  };
}

export const GetEventsForFilmstripSectionLazy = (
  params: QueryHookOptions<GetEventsForFilmstripSectionData, GetEventsVariables>
) => {
  return useLazyQuery<GetEventsForFilmstripSectionData, GetEventsVariables>(
    GET_EVENTS_FOR_FILMSTRIP_SECTION,
    params
  );
};
