import { useContext, useEffect, useState } from 'react';

import { getCurrentYearInTimeZone } from 'app/shared/utils/datetime';
import usePermission from 'app/shared/utils/usePermission';
import { AuthContext } from 'app/shared/context/Auth';

import { useRedemptions } from './useRedemptions';

const VISA_SESSION_EXPIRATION_TIME = 60 * 60 * 1000; /* 1 hour in ms */

const verificationHasNotExpired = (verificationTime?: string) => {
  if (!verificationTime) {
    return false;
  }
  const verificationDate = new Date(verificationTime);
  const timePassedSinceVerification = Date.now() - verificationDate.getTime();

  return timePassedSinceVerification < VISA_SESSION_EXPIRATION_TIME;
};

export const useVisaAuth = ({
  eventId,
  skipLoadCardRedemptionsStatus,
}: {
  eventId?: number;
  skipLoadCardRedemptionsStatus: boolean;
}) => {
  const authContext = useContext(AuthContext);

  if (authContext === null) {
    throw new Error(
      'useVisaAuth must be called within a child or grandchild of an AuthContext Provider'
    );
  }

  const {
    visa: {
      eligible,
      visaVerifiedAt,
      cardId,
      eligibilityLevel,
      cardLast4,
      cardType,
    },
    loading: sessionLoading,
  } = authContext;

  const calendarYear = getCurrentYearInTimeZone('America/New_York');
  const [loadedRedemptions, setLoadedRedemptions] = useState(false);

  const {
    loadCardRedemptionsStatus,
    cardRedemptionsStatusLoading,
    cardRedemptionsStatusData,
  } = useRedemptions();

  useEffect(() => {
    if (
      calendarYear &&
      cardId &&
      eligibilityLevel &&
      !loadedRedemptions &&
      !skipLoadCardRedemptionsStatus
    ) {
      loadCardRedemptionsStatus({
        variables: {
          calendarYear,
          cardId,
          eligibilityLevel,
          eventId,
          cardType,
        },
      });
      setLoadedRedemptions(true);
    }
  }, [
    calendarYear,
    cardId,
    eligibilityLevel,
    eventId,
    loadCardRedemptionsStatus,
    loadedRedemptions,
    skipLoadCardRedemptionsStatus,
  ]);

  const canBypassVisaValidation = usePermission('visaPresale.bypassValidation');

  const visaPreSaleEligible =
    !!eligible && verificationHasNotExpired(visaVerifiedAt);

  const resetLoadedCardRedemptionsStatus = () => {
    setLoadedRedemptions(false);
  };

  if (!cardId || !cardRedemptionsStatusData) {
    return {
      cardRedemptionsStatusLoading,
      visaPreSaleEligible,
      visaFreeTicketsRemaining: 0,
      visaFreeTicketRedeemedForEvent: false,
      cardLast4,
      cardType,
      eligibilityLevel,
      cardId,
      canBypassVisaValidation,
      sessionLoading,
      resetLoadedCardRedemptionsStatus,
    };
  }

  const {
    visaFreeTicketsRemaining,
    visaFreeTicketRedeemedForEvent,
  } = cardRedemptionsStatusData;

  return {
    cardRedemptionsStatusLoading,
    visaPreSaleEligible,
    visaFreeTicketsRemaining,
    visaFreeTicketRedeemedForEvent,
    cardLast4,
    cardType,
    eligibilityLevel,
    cardId,
    canBypassVisaValidation,
    sessionLoading,
    resetLoadedCardRedemptionsStatus,
  };
};
