import { GetCardRedemptionsStatusVariables } from 'app/typings';
import { GetCardRedemptionsStatusLazy } from 'app/shared/graphql/redemptions/queryHooks';

export const useRedemptions = (
  params: GetCardRedemptionsStatusVariables = {}
) => {
  const [
    loadCardRedemptionsStatus,
    { loading, data },
  ] = GetCardRedemptionsStatusLazy(params);

  const cardRedemptionsStatusData = data && data.cardRedemptionsStatus;

  return {
    loadCardRedemptionsStatus,
    cardRedemptionsStatusLoading: loading,
    cardRedemptionsStatusData,
  };
};
